@import '../../../../scss/theme-bootstrap';

// custom breakpoint and font sizes copied from editorial/_hero.scss
$tout-wide-up: 1280px;
$tout-x-wide-up: 1526px;
$tout-xx-wide-up: 1884px;

.basic-tout {
  position: relative;
  max-width: $max-width-large;
  margin: 0 auto;
  height: 100%;

  &__wrapper {
    position: relative;
    height: 100%;
  }

  &__media-wrapper {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  &__media:has(+ &__content-over-media) {
    img,
    picture,
    video {
      aspect-ratio: 406 / 675;
    }
  }

  &__media:has(+ &-content-below-block) {
    img,
    picture,
    video {
      aspect-ratio: 97 / 99;
    }
  }

  &__content-over-media {
    position: absolute;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    display: flex;
    flex-direction: column;
  }

  // Layout variation where image goes behind copy
  &--v2 {
    min-height: 300px;
    height: 100%;
    overflow: hidden;
    &.basic-tout--flexi-height {
      align-items: center;
      display: flex;
      .basic-tout__text {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        padding: 40px 30px;
      }
    }
  }
  // Layout variation where text is vertically centered. No absolute positioning here to ensure copy never gets cropped.
  &--v3 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 230px;
    padding: 30px;
    @include breakpoint($portrait-up) {
      min-height: 360px;
      padding: 70px;
    }
    @include breakpoint($landscape-up) {
      min-height: 640px;
      padding: 90px;
    }
  }
  &--white {
    background: $color-white;
    &.basic-tout--v1 {
      background: transparent;
    }
  }
  &--gray {
    background: $color-bg;
    &.basic-tout--v1 {
      background: transparent;
    }
  }
  &--black {
    background: $color-black;
    &.basic-tout--v1 {
      background: transparent;
    }
  }
  // Basic Tout with Overlay
  &--overlay {
    cursor: pointer;
  }
  &__video {
    width: 100%;
  }
}

// Show copy on hover modifier
// Enable only when .basic-tout--v2 exists and on non-touch devices
.basic-tout--v2.basic-tout--show-copy-on-hover {
  &:before {
    content: '';
    opacity: 1;
    background: $color-transparent--white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .no-touchevents & {
    position: relative;
    &:before {
      @include transition(opacity 0.2s ease-in-out);
      opacity: 0;
    }
    .basic-tout__text {
      @include transition(opacity 0.2s ease-in-out);
      opacity: 0;
    }
    &:hover {
      &:before,
      .basic-tout__text {
        opacity: 1;
      }
    }
  }
}

.basic-tout__text {
  padding: 5px 10px 0 10px;
  text-align: center;
  @include breakpoint($portrait-up) {
    padding: 5px 30px 0 30px;
  }
  &--dark {
    color: $color-black;
  }
  &--light {
    @include font-smoothing;
    color: $color-white;
  }
  // Layout variation where image goes behind copy
  .basic-tout--v2 & {
    width: 100%;
    position: absolute;
    z-index: 1;
    &--top-left {
      top: 0;
      left: 0;
      text-align: left;
    }
    &--top-center {
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    &--top-right {
      top: 0;
      right: 0;
      text-align: right;
    }
    &--center-left {
      @include vertical-center;
      text-align: left;
    }
    &--center-center {
      @include vertical-horizontal-center;
      text-align: center;
    }
    &--center-right {
      @include vertical-center;
      right: 0;
      text-align: right;
    }
    &--bottom-left {
      bottom: 0;
      left: 0;
      text-align: left;
    }
    &--bottom-center {
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    &--bottom-right {
      bottom: 0;
      right: 0;
      text-align: right;
    }
  }
  .basic-tout--v3 & {
    padding: 0;
  }
}

.basic-tout__title {
  @include font--heading;
  font-size: 25px;
  line-height: 24px;
  margin: 7px 0 0 0;
  > p {
    margin: 0;
    line-height: inherit;
  }
  @include breakpoint($portrait-up) {
    margin-top: 15px;
  }
  .basic-tout__text--light & {
    color: $color-white;
  }
  .basic-tout--v2 & {
    //@include font--heading-display;
    //font-size: 65px;
    //line-height: .75em;
    font-size: 36px;
    line-height: 30px;
    margin: 0;
  }
  .basic-tout--v3 & {
    @include font--heading-display;
    margin: 0;
  }
}

.basic-tout--v3 {
  .basic-tout__text--small .basic-tout__title {
    font-size: 14px;
    @include breakpoint($tout-x-wide-up) {
      font-size: 17px;
    }
    @include breakpoint($tout-xx-wide-up) {
      font-size: 21px;
    }
  }
  .basic-tout__text--medium .basic-tout__title {
    font-size: 19px;
    line-height: 1.075em;
    @include breakpoint($landscape-up) {
      font-size: 24px;
    }
    @include breakpoint($tout-wide-up) {
      font-size: 26px;
    }
    @include breakpoint($tout-x-wide-up) {
      font-size: 31px;
    }
    @include breakpoint($tout-xx-wide-up) {
      font-size: 38px;
    }
  }
  .basic-tout__text--large .basic-tout__title {
    font-size: 32px;
    line-height: 0.85em;
    @include breakpoint($landscape-up) {
      font-size: 46px;
      line-height: 1em;
    }
    @include breakpoint($tout-wide-up) {
      font-size: 51px;
    }
    @include breakpoint($tout-x-wide-up) {
      font-size: 56px;
    }
    @include breakpoint($tout-xx-wide-up) {
      font-size: 61px;
    }
  }
  .basic-tout__text--xl .basic-tout__title {
    font-size: 65px;
    line-height: 0.75em;
    @include breakpoint($landscape-up) {
      font-size: 117px;
    }
    @include breakpoint($tout-wide-up) {
      font-size: 126px;
    }
    @include breakpoint($tout-x-wide-up) {
      font-size: 150px;
    }
    @include breakpoint($tout-xx-wide-up) {
      font-size: 185px;
    }
  }
  .basic-tout__text--xxl .basic-tout__title {
    font-size: 91px;
    line-height: 0.725em;
    @include breakpoint($landscape-up) {
      font-size: 175px;
    }
    @include breakpoint($tout-wide-up) {
      font-size: 224px;
    }
    @include breakpoint($tout-x-wide-up) {
      font-size: 267px;
    }
    @include breakpoint($tout-xx-wide-up) {
      font-size: 330px;
    }
  }
}

.basic-tout__copy {
  margin: 6px 0 0 0;
  p {
    line-height: inherit;
    margin: 0;
  }
  .basic-tout--v2 & {
    &--headline {
      @include font-smoothing;
      @include font--subheading-alt;
      font-size: 19px;
      line-height: 1.075em;
    }
  }
  .basic-tout--v3 & {
    margin-top: 12px;
    @include breakpoint($portrait-up) {
      margin-top: 20px;
    }
  }
}

.basic-tout__cta {
  margin: 10px 0 0 0;
  @include breakpoint($portrait-up) {
    .basic-tout--v3 & {
      margin-top: 20px;
    }
  }
  &-button {
    @include link;
    .basic-tout__text--light & {
      @include font-smoothing;
      color: $color-white;
    }
  }
}

// Tout Overlay
.tout-overlay__header {
  @include h2;
  margin: 0 0 20px 0;
}

// AR Popup styles
.ar-popup {
  text-align: center;
  .ar-popup-title {
    @include font--heading;
    font-weight: normal;
    font-size: 32px;
    text-transform: uppercase;
  }
  .auto-delivery-image-wrapper {
    text-align: center;
  }
  .auto-delivery-image-pc {
    width: 100%;
    max-width: 846px;
    display: none;
    @include breakpoint($landscape-up) {
      display: inline-block;
    }
    .device-mobile & {
      display: none;
    }
  }
  .auto-delivery-image-mobile {
    display: inline-block;
    width: 100%;
    max-width: 786px;
    @include breakpoint($landscape-up) {
      display: none;
      width: auto;
      max-width: none;
    }
    .device-pc & {
      display: none;
    }
  }
}
